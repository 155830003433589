import React from 'react';
import './Card2.css';

const Card2 = ({ title, image, description, footer, footer2, footer3, errorMessage, successMessage }) => {
  return (
    <div className="card2">
      <div className="card2-header">
        <h2>{title}</h2>
      </div>
      <div className="card2-body">
        <p>{description}</p>
      </div>
      <div className="card2-footer">
        {footer3}
        {footer}
        {footer2}
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        {successMessage && <div style={{fontSize: '18px', color: 'Green' }}>{successMessage}</div>}
      </div>
    </div>
  );
};

export default Card2;
