import React from 'react';
import './Card3.css';

const Card3 = ({ title, image, description, footer, footer2, onInput1Change, errorMessage, successMessage}) => {
  return (
    <div className="card3">
      <div className="card-header3">
        <h2>{title}</h2>
      </div>
      <div className="card-body3">
        <div className="card-image3">
          <img src={image} alt={title} />
        </div>
        <div className="card-form3">
          <p>{description}</p>
          <form>
            <div className="form-group3">
              <label htmlFor="input1">Channel Route Name</label>
              <input
                type="text"
                id="input1"
                name="input1"
                placeholder="@NPR"
                onChange={onInput1Change}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="card-footer3">
        {footer2}
        {footer}
        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
        {successMessage && <div style={{fontSize: '18px', color: 'Green' }}>{successMessage}</div>}
      </div>
    </div>
  );
};

export default Card3;
