import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Card from './Components/Card';
import Card2 from './Components/Card2';
import './Components/SetupPage.css'
import tubeNotesImage from './Components/TubeNotes.jpg';

const SetupPage = () => {
  const [nick_name, setInput1] = useState('');
  const [favorite_thing, setInput2] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  // - ------- - -- - -Changes - -- - - - - - - - 
  const [channels, setChannels] = useState([]);
  const [toggleValues, setToggleValues] = useState({});
  
  useEffect(() => {
       // Fetch the JSON data
       fetch(`${process.env.REACT_APP_SERVER_IP}/api/return_channels`)
       .then(response => response.json())
       .then(data => {
         setChannels(data.channels);
         // Initialize toggle values
        //  console.log(data.channels)
         const initialToggleValues = {};
         data.channels.forEach(channel => {
          //  initialToggleValues[channel.channel_title] = false; // default toggle value
          initialToggleValues[channel.channel_title] = false; // Set Default Value
         });
         setToggleValues(initialToggleValues);
       })
       .catch(error => console.error('Error fetching data:', error));
   }, []);

   const handleToggleChange = (channelTitle) => {
    setToggleValues(prevValues => ({
      ...prevValues,
      [channelTitle]: !prevValues[channelTitle],
    }));
  };
  // -- -- - - - -- - - changes -- - -- - - 
  const handleInput1Change = (event) => {
    setInput1(event.target.value);
  };

  const handleInput2Change = (event) => {
    setInput2(event.target.value);
  };

  const handleSubmit = async () => {

    console.log('Input 1:', nick_name);
    console.log('Input 2:', favorite_thing);
    console.log('Toggle values:', toggleValues);
    const email = localStorage.getItem('email');

    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/initial_setup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nick_name: nick_name, favorite_thing: favorite_thing, email: email, subscriptions: toggleValues }),
      });
      if (res.status === 200) {
        setError('') // clear all errors
        localStorage.setItem('user_nickname', nick_name)
        navigate("/home");
      }
      else {
        console.log("Failed with: ", res.status)
        setError('Please fill out both fields!');
      }
    } catch (error) {
      console.error('Error!');
    }
  };

  return (
    <div>
      <Card
        title={"Setup"}
        image={tubeNotesImage}
        description="Before you proceed, let's answer a couple of questions..."
        footer={<p></p>}
        onInput1Change={handleInput1Change}
        onInput2Change={handleInput2Change}
      />
      <div>
        <Card2
          title="To get you started, select any channels from below that you want in your feed."
          description={

            <div className="channel-container">
            {channels.map(channel => (
              <div key={channel.channel_title} className="channel-item">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={toggleValues[channel.channel_title]}
                    onChange={() => handleToggleChange(channel.channel_title)}
                  />
                  <span className="slider round"></span>
                </label>
                <span className="channel-title">{channel.channel_title}</span>
              </div>
            ))}
          </div>
          }
          footer={<button className="fancy-green-button" onClick={handleSubmit}>Submit</button>}
          errorMessage={error}
        />
      </div>
    </div>
  );
};

export default SetupPage;
