import React from 'react';
import './TopBar.css'; // Import the CSS file for styling
import { useNavigate } from "react-router-dom";


const TopBar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    
    // Redirect to the logout route
    // window.location.href = '/logout';
    navigate('/login')
  };
  const handleFeed = () => {
    navigate('/feed')
  }
  const handleHome = () => {
    navigate('/home2')
  }
  const handleAbout = () => {
    navigate('/about')
  }
  
  return (
    <div className="top-bar">
      <div className="title-section">
        <h1>Tube Summaries</h1>
        
      </div>
      <nav>
        <a href='' onClick={handleHome}>Home</a>
        <a href='' onClick={handleAbout}>About</a>
        <a href='' onClick={handleFeed}>Feed Settings</a>
        <a href='' className="nav-right" onClick={handleLogout}>Logout</a>
      </nav>
    </div>
  );
};

export default TopBar;
