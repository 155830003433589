import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct named import
import HomePage from './HomePage';
import LoginPage from './LoginPage';
import HomePage2 from './Homepage2';
import SetupPage from './SetupPage';
import FeedPage from './FeedPage';
import AboutPage from './aboutPage';
// import './App.css';
// import './css/global.css'; // Import the global CSS file
import './index.css';


const PrivateRoute = ({ element: Element }) => {
  const isAuthenticated = () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
      const { exp } = jwtDecode(token); // Correct usage of jwtDecode
      if (Date.now() >= exp * 1000) {
        localStorage.removeItem('token');
        return false;
      }
      return true;
    } catch (error) {
      console.error('Invalid token:', error);
      return false;
    }
  };

  const authStatus = isAuthenticated();

  return authStatus ? <Element authStatus={authStatus} /> : <Navigate to="/login" />;
};


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<PrivateRoute element={(props) => <HomePage {...props} />} />} />
        <Route path="/home2" element={<PrivateRoute element={(props) => <HomePage2 {...props} />} />} />
        <Route path="/setup" element={<PrivateRoute element={(props) => <SetupPage {...props} />} />} />
        <Route path="/feed" element={<PrivateRoute element={(props) => <FeedPage {...props} />} />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default App;
