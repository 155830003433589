import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage = ({ authStatus }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus) {
      // Simulate a delay to mimic data fetching
      setTimeout(() => {
        setLoading(false);
        navigate('/home2');
      }, 3000); // Adjust the delay as needed
    }
  }, [authStatus, navigate]);
  
  return (
    <div>
      <h1>Welcome back {localStorage.getItem('user_nickname')}</h1>
      {loading ? <p>Loading some things for you, please wait...</p> : null}
    </div>
  );
};

export default HomePage;
