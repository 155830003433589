import React from 'react';
import styles from './UniqueArticleCard.module.css';

const ArticleCard = ({ articleTitle, articleContent, videoTitle }) => {
  // Split the content by hyphens and join with <br />
  const formattedContent = articleContent.split(/(?<![a-zA-Z])-/).map((part, index) => (
    <span key={index}>
      {index > 0 && <><br />• </>} {/* Add a <br /> and a bullet point before each part except the first */}
      {part.trim()}
    </span>
  ));  

  return (
    <div className={styles.uniqueCard}>
      <h2 className={styles.uniqueTitle}>{videoTitle}</h2>
      <div>
        <h3 className={styles.uniqueTitle}>{articleTitle}</h3>
        <p className={styles.uniqueContent}>{formattedContent}</p>
      </div>
    </div>
  );
};

export default ArticleCard;
