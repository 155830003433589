import React, { useEffect, useState } from 'react';
import VideoCard from './Components/VideoCard';
import ArticleCard from './Components/ArticleCard';
import TopBar from './Components/TopBar'; // Adjust the path as necessary
import './Components/HomePage2.css'; // Import the CSS file

const HomePage2 = () => {
  const [newsData, setNewsData] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Number of items per page
  const [total, setTotal] = useState(0); // Total number of items
  const [loading, setLoading] = useState(true);

  const fetchData = async (page, limit) => {
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage

    if (email && token) {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/homePage?page=${page}&limit=${limit}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` // Send the token in the Authorization header
          },
          body: JSON.stringify({ email }),
        });

        if (!response.ok) {
          // Check if the response is not okay (e.g., 401 Unauthorized)
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setNewsData(data.info); // Use the info property from the response
        setTotal(data.total); // Assuming the response includes the total number of items
        setLoading(false); // Set loading to false once data is fetched

      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
        setLoading(false); // Ensure loading is false even if there's an error
      }
    } else {
      setError('Email or token not found in localStorage');
      setLoading(false); // Ensure loading is false even if email/token is not found
    }
  };

  useEffect(() => {
    fetchData(page, limit);
  }, [page, limit]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    window.scrollTo(0, 0, { behavior: 'smooth' });
  };

  const totalPages = Math.ceil(total / limit);

  const renderPaginationButtons = () => {
    const pages = [];
    const maxButtons = 5; // Max number of pagination buttons to show
    const halfMaxButtons = Math.floor(maxButtons / 2);
  
    let startPage = Math.max(1, page - halfMaxButtons);
    let endPage = Math.min(totalPages, page + halfMaxButtons);
  
    if (endPage - startPage + 1 < maxButtons) {
      if (startPage === 1) {
        endPage = Math.min(totalPages, startPage + maxButtons - 1);
      } else if (endPage === totalPages) {
        startPage = Math.max(1, totalPages - maxButtons + 1);
      }
    }
  
    if (startPage > 1) {
      pages.push(
        <button key={1} onClick={() => handlePageChange(1)} className="page-button">{1}</button>
      );
      if (startPage > 2) {
        pages.push(<span key="start-ellipsis" className="ellipsis">...</span>);
      }
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          disabled={i === page}
          className={`page-button ${i === page ? 'active' : ''}`}
        >
          {i}
        </button>
      );
    }
  
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(<span key="end-ellipsis" className="ellipsis">...</span>);
      }
      pages.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)} className="page-button">
          {totalPages}
        </button>
      );
    }
  
    return pages;
  };
  

  return (
    <div>
      <TopBar />
      <div style={{ paddingTop: '80px' }}>
        {loading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
            <p>Loading...</p>
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : newsData.length > 0 ? (
          <div>
            {newsData.map((item, index) => (
              <div key={index}>
                <VideoCard 
                  channelName={item.channel_title}
                  videoTitle={item.video_title}
                  thumbnailUrl={item.video_thumbnail_url}
                  videoUrl={'https://www.youtube.com/embed/' + item.video_id}
                />
                <ArticleCard
                  videoTitle={item.video_title}
                  articleContent={item.article}
                />
              </div>
            ))}
            <div className="pagination-container">
              {renderPaginationButtons()}
            </div>
          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default HomePage2;
