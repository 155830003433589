import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import Card2 from './Components/Card2';
import Card3 from './Components/Card3';
import './Components/FeedPage.css';
import TopBar from './Components/TopBar'; // Adjust the path as necessary
import tutorialImage from './Components/tutorial.png';

const FeedPage = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [error2, setError2] = useState('');
  const [success2, setSuccess2] = useState('');
  const [channel_url, setInput1] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Changes 
  const [channels, setChannels] = useState([]);
  const [toggleValues, setToggleValues] = useState({});
  const hiddenRef = useRef(null);
  
  const handleInput1Change = (event) => {
    setInput1(event.target.value);
  };

  useEffect(() => {
    // Fetch the JSON data
    const email = localStorage.getItem('email');
    fetch(`${process.env.REACT_APP_SERVER_IP}/api/return_subscriptions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {
      setChannels(data['channels']);
      // Initialize toggle values
      const initialToggleValues = {};
      data.channels.forEach(channel => {
        initialToggleValues[channel.channel_title] = channel.subscription_status; // Set Default Value
      });
      setToggleValues(initialToggleValues);
      setLoading(false); // Set loading to false once data is fetched
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setLoading(false); // Ensure loading is false even if there's an error
    });
  }, []);
  
  const handleToggleChange = (channelTitle) => {
    setToggleValues(prevValues => ({
      ...prevValues,
      [channelTitle]: !prevValues[channelTitle],
    }));
  };

  const handleSubmit = async () => {
    const email = localStorage.getItem('email');
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/update_feed`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email, subscriptions: toggleValues }),
      });
      if (res.status === 200) {
        setError('');
        setSuccess('Successfully Updated!');
        navigate("/home2");
      } else {
        setError2('There was an error!!');
      }
    } catch (error) {
      console.error('Error!');
    }
  };

  const handleSubmit2 = async () => { 
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token');
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/set_new_channel`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ email: email, channel_url: channel_url }),
      });

      const message = await res.text();
      if (res.status === 200) {
        setError2('');
        setSuccess2('Success!');
        setTimeout(() => {
        window.location.reload();
        }, 1500);
      } else {
        setError2(message);
      }
    } catch (error) {
      console.log("Error in handleSubmit2");
      setError2('Another error occurred');
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setTimeout(() => {
      hiddenRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <div>
      <TopBar />
      {loading ? (
        <div className="spinner-container">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          <div className='div3'></div>
          <Card2
            title="Select any channels from below that you want or don't want in your feed."
            description={
              <div className="channel-container">
                {channels.map(channel => (
                  <div key={channel.channel_title} className="channel-item">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={toggleValues[channel.channel_title]}
                        onChange={() => handleToggleChange(channel.channel_title)}
                      />
                      <span className="slider round"></span>
                    </label>
                    <span className="channel-title">{channel.channel_title}</span>
                  </div>
                ))}
              </div>
            }
            footer={<button className="fancy-green-button" onClick={handleSubmit}>Update</button>}
            footer2={<button className="plus-button" onClick={toggleVisibility}>+</button>}
            footer3={<h4>Not listed? Click Here</h4>}
            errorMessage={error}
            successMessage={success}
          />
          <div ref={hiddenRef} style={{ display: isVisible ? 'block' : 'none' }}>
            <Card3 
              image={tutorialImage}
              title="If you want to add a channel that you don't see here, fill this out."
              onInput1Change={handleInput1Change}
              footer={<button className="fancy-green-button" onClick={handleSubmit2}>Add</button>}
              errorMessage={error2}
              successMessage={success2}
            />
          </div>
        </div>
      )}
    </div> 
  );
};

export default FeedPage;
