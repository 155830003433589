import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import "./Form.css";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleGoogleLoginSuccess = async (response) => {
    const token = response.credential;
    if (token) {
      try {
        const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/auth/google`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        const data = await res.json();
        if (data.token) {
          // console.log("data.token: " + data.token);
          // console.log("data.email: " + data.email);
          localStorage.setItem("token", data.token);
          localStorage.setItem("email", data.email);
          localStorage.setItem("user_nickname", data.user_nickname);
          // check if account is setup or not
          const email = data.email;
          const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/setupcheck`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email }),
          });
          const setup_status = await res.json();
          console.log(setup_status.is_setup);
          if (setup_status.is_setup === false) {
            navigate("/setup");
          } else {
            navigate("/home");
          }
        }
      } catch (error) {
        console.error("Error sending token to backend:", error);
      }
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Login Failed:", error);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await res.json();
      if (data.token) {
        // console.log("data.token: " + data.token);
        // console.log("data.email: " + data.email);
        localStorage.setItem("token", data.token);
        localStorage.setItem("email", data.email);
        localStorage.setItem("user_nickname", data.user_nickname);
        const email = data.email;
        const res = await fetch(`${process.env.REACT_APP_SERVER_IP}/api/setupcheck`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        const setup_status = await res.json();
        console.log(setup_status.is_setup);
        if (setup_status.is_setup === false) {
          navigate("/setup");
        } else {
          navigate("/home");
        }
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (!clientId) {
    console.error("Google Client ID is missing");
    return <div>Error: Google Client ID is missing</div>;
  }

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div className="form-container login-page">
        <h2 className="form-container-text">Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit">Login</button>
          <h2 className="form-container-text">OR</h2>
        </form>
        <div className="div-1">
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={handleGoogleLoginFailure}
        />
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
