import React from 'react';
import styles from './UniqueVideoCard.module.css';

const VideoCard = ({ channelName, videoUrl, thumbnailUrl, videoTitle }) => {
  // Extract the video ID from the YouTube URL
  // const videoId = videoUrl.split('v=')[1];
  // const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className={styles.uniqueCard}>
      <div className={styles.uniqueLeft}>
        <h3 className={styles.uniqueTitle}>{channelName}</h3>
        <a href={videoUrl} target="_blank" rel="noopener noreferrer" className={styles.uniqueLink}>
          Watch Video
        </a>
      </div>
      <div className={styles.uniqueRight}>
      <iframe
          title={videoTitle}
          src={videoUrl}
          className={styles.uniqueIframe}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default VideoCard;
