import React from 'react';
import styles from './Components/About.module.css';
import TopBar from './Components/TopBar'; // Adjust the path as necessary

const AboutPage = () => {
  return (
    <div><TopBar />
      <div className={styles.aboutContainer}>
        <h1 className={styles.heading}>About Us</h1>
        <p className={styles.paragraph}>
          Welcome to our website! We are dedicated to providing you with the best content and services.
        </p>
        <div className={styles.section}>
          <h2 className={styles.subheading}>Our Mission</h2>
          <p className={styles.paragraph}>
            Our mission is to inspire and empower people through our innovative solutions and services.
          </p>
        </div>
        <div className={styles.section}>
          <h2 className={styles.subheading}>Our Team</h2>
          <p className={styles.paragraph}>
            We have a diverse team of professionals who are passionate about what they do.
          </p>
        </div>
        <div className={styles.section}>
          <h2 className={styles.subheading}>Contact Us</h2>
          <p className={styles.paragraph}>
            Feel free to reach out to us for any inquiries or feedback.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;