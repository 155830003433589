import React from 'react';
import './Card.css';

const Card = ({ title, image, description, footer, onInput1Change, onInput2Change }) => {
  return (
    <div className="card">
      <div className="card-header">
        <h2>{title}</h2>
      </div>
      <div className="card-body">
        <div className="card-image">
          <img src={image} alt={title} />
        </div>
        <div className="card-form">
          <p>{description}</p>
          <form>
            <div className="form-group">
              <label htmlFor="input1">Tell me your NickName!!!</label>
              <input
                type="text"
                id="input1"
                name="input1"
                placeholder="Alphinaud"
                onChange={onInput1Change}
              />
            </div>
            <div className="form-group">
              <label htmlFor="input2">What is your favorite thing?</label>
              <input
                type="text"
                id="input2"
                name="input2"
                placeholder="The John Wick series"
                onChange={onInput2Change}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="card-footer">
        {footer}
      </div>
    </div>
  );
};

export default Card;
